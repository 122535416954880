import { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { CloseIcon } from '@components/icons';
import { Caption } from '@components/styles/Typography';

const customStyles = {
    overlay: {
        zIndex: '999',
        backgroundColor: 'rgba(0,0,0, 0.6)',
    },
    content: {},
};

Modal.setAppElement('#__next');

const SignupTermsModal = ({ content, title }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <ModalWrapper>
            <Caption onClick={openModal}>{title}</Caption>
            <StyledModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Signup Terms Modal"
            >
                <CloseButton onClick={closeModal}>
                    <CloseIcon />
                </CloseButton>
                {content}
            </StyledModal>
        </ModalWrapper>
    );
};
const StyledModal = styled(Modal)`
    position: fixed;
    width: 510px;
    height: 495px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 3px;
    outline: none;
    background: ${({ theme }) => theme.colors.secondary};

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translate(0);
    }
`;
const ModalWrapper = styled.div`
    display: inline;

    span {
        cursor: pointer;
        text-decoration: underline;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    cursor: pointer;
`;

export default SignupTermsModal;
